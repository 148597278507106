import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import AcademyCourseDoneView from "src/academy/course/AcademyCourseDoneView/AcademyCourseDoneView";
import AcademyCoursePreview from "src/academy/course/AcademyCoursePreview/AcademyCoursePreview";
import AcademyLessonView from "src/academy/course/AcademyLessonView/AcademyLessonView";
import AcademyTestView from "src/academy/course/AcademyTestView/AcademyTestView";
import AcademyCourseSidebar from "src/academy/course/sidebar/AcademyCourseSidebar/AcademyCourseSidebar";
import { IAcademyChapter, IAcademyCourse, IAcademyLesson, IAcademyTest, ICourseStep } from "src/academy/types";
import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import api from "src/spintr/SpintrApi";
import { Breadcrumbs, ContentWithInfoPanel, Label, setAdminNoPadding, setDisableWrapperBackground } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import "./AcademyCourseView.scss";
import { getActiveStep, getNextStep, isLastStep, markStepAsCompleted, updateCourseEligibality } from "src/academy/utils";
import PopupHeader from "src/ui/components/PopupHeader";
import { Modal } from "@fluentui/react";
import { cleanForms } from "src/utils/isAnythingDirty";
import { setSidebarMode, VisageSidebarMode } from "src/sidebar";

interface IProps {
    currentUser?: IActiveUserProfile;
    appEnabled?: boolean;
    appMode?: boolean;
    academyAppName?: string;
    history?: any;
    isSmallViewMode?: boolean;
}

interface IState {
    isLoading: boolean;
    isLoadingAttendance: boolean;
    course?: IAcademyCourse;
    displayPreview: boolean;
    displayReviewModal: boolean;
    displayCongratulations: boolean;
    activeStep?: ICourseStep;
}

const AcademyCourseView = (props: IProps) => {
    const dispatch = useDispatch();
    const [state, setState] = useState<IState>({
        isLoading: true,
        isLoadingAttendance: false,
        displayPreview: true,
        displayReviewModal: false,
        displayCongratulations: false
    });

    useEffect(() => {
        if (!props.appEnabled) {
            return props.history.push("/404");
        }

        fetchCourse();
        dispatch(setDisableWrapperBackground(true));
        dispatch(setAdminNoPadding(true));
        dispatch(setSidebarMode(VisageSidebarMode.noMenu));

        return () => {
            dispatch(setDisableWrapperBackground(false));
            dispatch(setAdminNoPadding(false));
            dispatch(setSidebarMode(VisageSidebarMode.menu));
        }
    }, []);

    const fetchCourse = useCallback(() => {
        const id = window.location.href.split("/").pop();

        api.get("/api/v1/academy/courses/" + id).then((response: AxiosResponse) => {
            const course: IAcademyCourse = updateCourseEligibality(response.data);

            setState((s: IState) => ({
                ...s,
                isLoading: false,
                course,
                activeStep: getActiveStep(course)
            }));
        }).catch(() => {});
    }, []);

    const renderSidebar = () => (
        <AcademyCourseSidebar
            course={state.course}
            activeStepId={state.activeStep?.id}
            displayPreview={state.displayPreview}
            onReviewCourse={() => {
                setState((s: IState) => ({
                    ...s,
                    displayReviewModal: true
                }));
            }}
            onOpenCourse={() => {
                setState((s: IState) => ({
                    ...s,
                    displayPreview: false
                }));
            }}
            onJoinCourse={() => {
                setState((s: IState) => ({
                    ...s,
                    course: {
                        ...s.course,
                        userProgress: {
                            ...s.course.userProgress,
                            isInitialized: true
                        },
                    },
                    displayPreview: false
                }));

                fetchCourse();
            }}
            onCancelCourse={() => {
                setState((s: IState) => ({
                    ...s,
                    course: {
                        ...s.course,
                        userProgress: {
                            ...s.course.userProgress,
                            isInitialized: false
                        }
                    }
                }));

                fetchCourse();
            }}
            goToLesson={(lesson: IAcademyLesson) => {
                setState((s: IState) => ({
                    ...s,
                    activeStep: {
                        id: lesson.id,
                        type: "lesson"
                    }
                }));
            }}
            goToTest={(test: IAcademyTest) => {
                setState((s: IState) => ({
                    ...s,
                    activeStep: {
                        id: test.id,
                        type: "test"
                    }
                }));
            }}
            goToFinalExam={() => {
                setState((s: IState) => ({
                    ...s,
                    activeStep: {
                        id: state.course.test.id,
                        type: "test"
                    }
                }));
            }}
        />
    );

    if (state.isLoading) {
        return <SpintrLoader />
    }

    return (
        <div className="AcademyCourseView">
            <ContentWithInfoPanel
                template={1}
                visagePageStyle
                renderInfoPanel={renderSidebar}>
                <Helmet>
                    <title>{localize(props.academyAppName, true)}</title>
                </Helmet>
                <Breadcrumbs
                    hideInstance={props.appMode}
                    items={[
                        {
                            key: "0",
                            text: localize(props.academyAppName, true),
                            link: "/academy",
                        },
                        {
                            key: "1",
                            text: state.course.name,
                            link: "/academy/" + state.course.id,
                        }
                    ]}
                />
                {state.displayPreview && (
                    <AcademyCoursePreview course={state.course} />
                )}
                {!state.displayPreview && !!state.activeStep && (
                    <div>
                        {state.activeStep.type === "lesson" && (
                            <AcademyLessonView
                                key={state.activeStep.id}
                                id={state.activeStep.id}
                                isLastStep={isLastStep(state.course, state.activeStep.id)}
                                onDone={() => {
                                    const course = updateCourseEligibality(
                                        markStepAsCompleted(
                                            {
                                                ...state.course,
                                                userProgress: {
                                                    ...state.course.userProgress,
                                                },
                                            },
                                            state.activeStep.id
                                        )
                                    );
                                    const displayCongratulations = !state.course.userProgress.isCompleted && course.userProgress.isCompleted;

                                    setState((s: IState) => ({
                                        ...s,
                                        course,
                                        activeStep: getNextStep(course, state.activeStep.id) || s.activeStep,
                                        displayReviewModal: displayCongratulations,
                                        displayCongratulations 
                                    }));
                                }} />
                        )}
                        {state.activeStep.type === "test" && (
                            <AcademyTestView
                                key={state.activeStep.id}
                                id={state.activeStep.id}
                                isLastStep={isLastStep(state.course, state.activeStep.id)}
                                isFinalExam={state.activeStep.id === state.course.test?.id}
                                goToNextStep={() => {
                                    setState((s: IState) => ({
                                        ...s,
                                        activeStep: getNextStep(state.course, state.activeStep.id) || s.activeStep
                                    }));
                                }}
                                onDone={(test: IAcademyTest) => {
                                    const c = state.course.userProgress.isCompleted;
                                    const course = test.userProgress.isCompleted ?
                                        updateCourseEligibality(markStepAsCompleted({...state.course}, state.activeStep.id)) :
                                        state.course;

                                    const displayCongratulations = !c && course.userProgress.isCompleted;

                                    setState((s: IState) => ({
                                        ...s,
                                        course,
                                        displayReviewModal: displayCongratulations,
                                        displayCongratulations 
                                    }));
                                }} />
                        )}
                    </div>
                )}
            </ContentWithInfoPanel>
            {props.isSmallViewMode && renderSidebar()}
            {state.displayReviewModal && (
                <Modal
                    className={[
                        "spintr-modal",
                        "academy-modal",
                        "course-review-modal"
                    ].filter(x => !!x).join(" ")}
                    allowTouchBodyScroll
                    isOpen={true}
                    onDismiss={() => {
                        setState((s: IState) => {
                            return {
                                ...s,
                                displayReviewModal: false
                            }
                        });
                    }}
                    styles={{
                        main: {
                            padding: 20,
                        },
                    }}>
                    <AcademyCourseDoneView
                        course={state.course}
                        displayCongratulations={state.displayCongratulations}
                        onClose={() => {
                            cleanForms();
                            if (state.displayCongratulations) {
                                setState((s: IState) => {
                                    return {
                                        ...s,
                                        isLoading: true
                                    }
                                });

                                props.history.push("/academy");

                                return;
                            }

                            setState((s: IState) => {
                                return {
                                    ...s,
                                    displayReviewModal: false,
                                    displayCongratulations: false
                                }
                            });
                        }}
                        onUpdate={(course: IAcademyCourse) => {
                            cleanForms();
                            setState((s: IState) => {
                                return {
                                    ...s,
                                    course
                                }
                            });
                        }} />
                </Modal>
            )}
        </div>
    )
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    currentUser: state.profile.active,
    appEnabled: state.app.items.some((app) => app.enabled && app.id === 32),
    appMmode: state.ui.appMode,
    academyAppName: state.instance.get("academyAppName"),
    isSmallViewMode: state.ui.isSmallViewMode,
});

export default withRouter(connect(mapStateToProps)(AcademyCourseView));
