import * as MessageActions from "./message-actions";
import { ChatReducerHandler } from "./types";

export const queryMessagesPending: ChatReducerHandler<MessageActions.IQueryMessagesPendingAction> =
    (draft, action) => {
        draft.messages.isLoading[action.meta.conversationId] = true;
    };

export const queryMessagesRejected: ChatReducerHandler<MessageActions.IQueryMessagesRejectedAction> =
    (draft, action) => {
        draft.messages.isLoading[action.meta.conversationId] = false;
    };

export const queryMessagesFulFilled: ChatReducerHandler<MessageActions.IQueryMessagesFulFilledAction> =
    (draft, action) => {
        draft.messages.items = [
            ...action.payload.data
                .filter(m => draft.messages.items.every(msg => msg.id !== m.id))
                .map((x) => ({...x, date: new Date(x.date)})),
            ...draft.messages.items
        ].sort((a, b) => a.date < b.date ? -1 : 1);

        draft.messages.isLoading[action.meta.conversationId] = false;
        draft.messages.hasMore[action.meta.conversationId] = action.payload.data.length === action.meta.take;

        const currentVirtuosoIndex = draft.messages.virtuosoIndex[action.meta.conversationId];

        draft.messages.virtuosoIndex[action.meta.conversationId] = (currentVirtuosoIndex || 100000) - action.payload.data.length;
    };

export const queryPinnedMessagesFulfilled: ChatReducerHandler<MessageActions.IQueryPinnedMessagesFulfilledAction> =
    (draft, action) => {
        draft.conversations.pinnedMessages[action.meta.conversationId] = action.payload.data;
    };

export const addMessageFulfilled: ChatReducerHandler<MessageActions.IMessageReceivedAction> =
    (draft, action) => {
        if (action.meta.message.isSystem) {
            return;
        }

        if (draft.messages.items.some((m) => m.id === action.meta.message.id) ||
            (action.meta.message.referenceId && draft.messages.items.some((m) => m.referenceId === action.meta.message.referenceId))) {
            return;
        }

        draft.messages.items = [
            ...draft.messages.items,
            {
                ...action.meta.message,
                date: new Date(action.meta.message.date)
            },
        ];
    };

export const removeMessageFulfilled: ChatReducerHandler<MessageActions.IMessageDeletedAction> =
    (draft, action) => {
        draft.messages.items = draft.messages.items.filter((m) => m.id !== action.meta.messageId);
    };

export const pinMessageFulfilled: ChatReducerHandler<MessageActions.IMessagePinnedAction> =
    (draft, action) => {
        draft.messages.items = draft.messages.items.map(m => {
            if (m.id !== action.meta.message.id) {
                return m;
            }

            return {
                ...m,
                isPinned: true,
            };
        });

        if (!draft.conversations.pinnedMessages[action.meta.message.conversationId]) {
            draft.conversations.pinnedMessages[action.meta.message.conversationId] = [];
        }

        if (!draft.conversations.pinnedMessages[action.meta.message.conversationId].some(x => x.id === action.meta.message.id)) {
            draft.conversations.pinnedMessages[action.meta.message.conversationId].push(action.meta.message);
        }
    };

export const unpinMessageFulfilled: ChatReducerHandler<MessageActions.IMessageUnpinnedAction> =
    (draft, action) => {
        draft.messages.items = draft.messages.items.map(m => {
            if (m.id !== action.meta.message.id) {
                return m;
            }

            return {
                ...m,
                isPinned: false,
            };
        });

        if (draft.conversations.pinnedMessages[action.meta.message.conversationId]) {
            draft.conversations.pinnedMessages[action.meta.message.conversationId] = draft.conversations.pinnedMessages[action.meta.message.conversationId].filter(x => x.id !== action.meta.message.id);
        }
    };

export const removeReaction: ChatReducerHandler<MessageActions.RemoveReactionAction> =
    (draft, action) => {
        const message = draft.messages.items.find(x => x.id === action.meta.messageId);

        if (!message || !message.reactions.some(x => x.user.id === action.meta.userId && x.likeType === action.meta.likeType)) {
            return;
        }

        draft.messages.items = draft.messages.items.map(m => {
            if (m.id !== action.meta.messageId) {
                return m;
            }

            const existingReaction = m.reactions.find(r =>
                r.likeType === action.meta.likeType &&
                r.user.id === action.meta.userId);

            if (!existingReaction) {
                return m;
            }

            return {
                ...m,
                reactions: [
                    ...m.reactions.slice(0, m.reactions.indexOf(existingReaction)),
                    ...m.reactions.slice(m.reactions.indexOf(existingReaction) + 1)
                ]
            };
        });
    };

export const addReaction: ChatReducerHandler<MessageActions.AddReactionAction> =
    (draft, action) => {
        const message = draft.messages.items.find(x => x.id === action.meta.messageId);

        if (!message || message.reactions.some(x => x.user.id === action.meta.userId && x.likeType === action.meta.likeType)) {
            return;
        }

        draft.messages.items = draft.messages.items.map(m => {
            if (m.id !== action.meta.messageId) {
                return m;
            }

            const existingReaction = m.reactions.find(r =>
                r.likeType === action.meta.likeType &&
                r.user.id === action.meta.userId);

            if (!!existingReaction) {
                return m;
            }

            return {
                ...m,
                reactions: [
                    ...m.reactions.filter(x => x.user.id !== action.meta.userId),
                    {
                        likeType: action.meta.likeType,
                        user: {
                            id: action.meta.userId,
                            name: action.meta.userName
                        }
                    }
                ]
            };
        });
    };

export const saveMessagePending: ChatReducerHandler<MessageActions.ISaveMessagePendingAction> =
    (draft, action) => {
        if (draft.messages.items.some(x => x.id === action.meta.message.id)) {
            return;
        }

        draft.messages.items = [
            ...draft.messages.items,
            {
                ...action.meta.message,
                date: new Date(action.meta.message.date)
            },
        ];
    };

export const saveMessageFulfilled: ChatReducerHandler<MessageActions.ISaveMessageFulfilledAction> =
    (draft, action) => {
        draft.messages.items = draft.messages.items.map((m) => {
            if (m.id !== action.meta.message.id) {
                return m;
            }

            if (!!action.payload.data.user &&
                !action.payload.data.user.imageUrl &&
                !!m.user &&
                !!m.user.imageUrl) {
                action.payload.data.user.imageUrl = m.user.imageUrl;
            }

            return {
                ...action.payload.data,
                date: new Date(action.payload.data.date)
            };
        });
    };

export const saveMessageRejected: ChatReducerHandler<MessageActions.ISaveMessageRejectedAction> =
    (draft, action) => {
        draft.messages.items = draft.messages.items.map((m) => {
            if (m.id !== action.meta.message.id) {
                return m;
            }

            return {
                ...m,
                savingFailed: true,
            };
        });
    };

export const memoTodoUpdated: ChatReducerHandler<MessageActions.IMemoTodoUpdatedAction> =
    (draft, action) => {
        draft.messages.items = draft.messages.items.map((m) => {
            if ((m.objects || []).find(x => x.type === 28 && x.todos.find(t => t.id === action.meta.id))) {
                return {
                    ...m,
                    objects: m.objects.map((o) => {
                        if (o.type === 28) {
                            return {
                                ...o,
                                todos: o.todos.map((t) => {
                                    if (t.id === action.meta.id) {
                                        console.log({...t})
                                        console.log({...action.meta})
                                        return action.meta;
                                    }

                                    return t;
                                })
                            }
                        }

                        return o;
                    })
                }
            }

            return m;
        });
    }

export const updateMessageFullfilled: ChatReducerHandler<MessageActions.IUpdateMessageFulfilledAction> =
    (draft, action) => {
        draft.messages.items = draft.messages.items.map((m) => {
            if (m.id === action.meta.message.id) {
                return {
                    ...action.meta.message,
                    date: m.date
                };
            }

            if (!!m.parent && m.parent.id === action.meta.message.id) {
                return {
                    ...m,
                    parent: {
                        ...action.meta.message,
                        date: m.parent.date
                    }
                }
            }

            return m;
        });
    }
